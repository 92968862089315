import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { User } from "../models/auth.models";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  get LoggedInUser() {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(environment.API_URL + "/api/auth/login", {
        email,
        password,
        type: "admin",
      })
      .pipe(
        map((res) => {
          let user = res.data;
          // login successful if there's a jwt token in the response
          console.log(user);
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(environment.TOKEN_NAME, user.token);
            localStorage.setItem(environment.CURRENT_USER, JSON.stringify(user));
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(environment.TOKEN_NAME);
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    location.reload();
  }

  getUsersWithType(type: string) {
    return this.http
      .get(environment.API_URL + `/api/auth/listType/` + type)
      .pipe(map((res: any) => res.data));
  }

  ActivateDeactivate(id, active) {
    return this.http
      .post(environment.API_URL + `/api/auth/activateDeactivate`, {
        id,
        active,
      })
      .pipe(map((res: any) => res.data));
  }

  updateProfile(id, body) {
    body.type = environment.USER_TYPE;
    return this.http.post(
      environment.API_URL + '/api/auth/profile/update/' + id,
      body
    );
  }

  getProfile() {
    return this.http.get(environment.API_URL + '/api/auth/profile').pipe(
      map((res: any) => {
        this.currentUserSubject.next(res.data);
        return res.data;
      })
    );
  }

}
