import { Injectable } from "@angular/core";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./auth.service";
import { FirebaseApp } from "@angular/fire/app";
import { AngularFirestore } from "@angular/fire/compat/firestore";
@Injectable({
  providedIn: "root",
})
export class NotificationService {
  userId;
  constructor(
    private authService: AuthenticationService,
    private test: FirebaseApp,
    private fdb: AngularFirestore
  ) {
    this.userId = JSON.parse(
      localStorage.getItem(environment.CURRENT_USER)
    )?._id;
    console.log("user id ", this.userId);
  }

  async requestPermission() {
    const messaging = getMessaging(this.test);
    await getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token", currentToken);

          localStorage.setItem(environment.FIREBASE_TOKEN, currentToken);
          this.updateToken(currentToken);
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {});
  }

  updateToken(token: string) {
    const user = this.authService.LoggedInUser;
    console.log("user", user);

    user.firebaseToken = user.firebaseToken ? user.firebaseToken : [];
    if (user.firebaseToken.includes(token)) {
      return;
    }
    user.firebaseToken.push(token);
    this.authService.updateProfile(this.userId, user).subscribe((res) => {
      console.log(res);
    });
  }

  getNotificationUnread() {
    const adminUnreadDoc = 'adminUnreadDoc'
    return this.fdb.collection("notifications").doc(adminUnreadDoc).valueChanges();
  }

  getAllNotifications() {
    return this.fdb
    .collectionGroup('userNotification')
      // .collection("notifications")
      // .doc(this.userId)
      // .collection("userNotification")
      .valueChanges();
  }
  updateUnread() {
    const adminUnreadDoc = 'adminUnreadDoc'
    return this.fdb
      .collection("notifications")
      .doc(adminUnreadDoc)
      .update({ unread: 0 });
  }
}
