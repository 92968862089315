import { MenuItem } from "../models/menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.MENU.TEXT",
    isTitle: true,
  },
  {
    id: 2,
    label: "MENUITEMS.DASHBOARDS.TEXT",
    icon: "bx-home-circle",
    link: "/dashboard",
    badge: {
      variant: "info",
      text: "MENUITEMS.DASHBOARDS.BADGE",
    },
  },
  // {
  //   id: 3,
  //   label: "الملف الشخصي",
  //   icon: "bx-home-circle",
  //   link: "/profile",
  // },
  {
    id: 3,
    label: "الاعدادات",
    icon: "bx-home-circle",
    link: "/settings",
  },
  {
    id: 4,
    label: "المطابخ",
    icon: "fas fa-user",
    link: "/kitchens",
  },
  {
    id: 6,
    label: "عرض الطلبات",
    icon: "bx-home-circle",
    link: "/order",
  },
  {
    id: 5,
    label: "عرض الطلبات",
    icon: "bx-home-circle",
    link: "/orderFS",
    badge: {
      variant: "warning",
      text: "MENUITEMS.Orders.NEW",
    },
  },
  {
    id: 7,
    label: "عرض العملاء",
    icon: "bx-home-circle",
    link: "/clients",
  },
  // {
  //   id: 8,
  //   label: "عرض عمال التوصيل",
  //   icon: "bx-home-circle",
  //   link: "/captin",
  // },

  {
    id: 9,
    isLayout: true,
  },

  // {
  //   id: 5,
  //   label: "رسوم الدفع",
  //   icon: "fas fa-user-times",
  //   subItems: [
  //     {
  //       id: 21,
  //       label: "اضافه رسوم",
  //       link: "/pay-charge/add-charge",
  //       parentId: 20,
  //     },
  //     {
  //       id: 22,
  //       label: "عرض الرسوم",
  //       link: "/view-charge",
  //       parentId: 20,
  //     },
  //   ],
  // },
  {
    id: 13,
    label: "رسوم التوصيل",
    icon: "bx bx-user",
    subItems: [
      {
        id: 21,
        label: "اضافه رسوم توصيل",
        link: "/view-charge/add-delivery-charge",
        parentId: 20,
      },
      {
        id: 22,
        label: "عرض رسوم توصيل",
        link: "/view-charge",
        parentId: 20,
      },
    ],
  },
  {
    id: 10,
    label: "قوائم الطلبات",
    icon: "bx bx-user",
    subItems: [
      {
        id: 21,
        label: "اضافه قائمة",
        link: "/create-menu",
        parentId: 20,
      },
      {
        id: 22,
        label: "عرض القوائم",
        link: "/view-menu",
        parentId: 20,
      },
    ],
  },
  // {
  //   id: 11,
  //   label: "القواعد والتصريحات",
  //   icon: "bx bx-user",
  //   subItems: [
  //     {
  //       id: 21,
  //       label: "اضافه قاعدة",
  //       link: "/create-role",
  //       parentId: 20,
  //     },
  //     {
  //       id: 22,
  //       label: "عرض القواعد",
  //       link: "/view-role",
  //       parentId: 20,
  //     },
  //   ],
  // },
  // {
  //   id: 12,
  //   label: "الموظفين",
  //   icon: "fas fa-user",
  //   subItems: [
  //     {
  //       id: 21,
  //       label: "اضافه موظف",
  //       link: "/add-employee",
  //       parentId: 20,
  //     },
  //     {
  //       id: 22,
  //       label: "عرض موظف",
  //       link: "/view-employee",
  //       parentId: 20,
  //     },
  //   ],
  // },
  {
    id: 13,
    label: "الدول",
    icon: "fas fa-align-justify",
    subItems: [
      {
        id: 21,
        label: "اضافه دولة",
        link: "/add-country",
        parentId: 20,
      },
      {
        id: 22,
        label: "عرض دول",
        link: "/view-country",
        parentId: 20,
      },
    ],
  },
  {
    id: 14,
    label: "المدن",
    icon: "fas fa-align-justify",
    subItems: [
      {
        id: 21,
        label: "اضافه مدينة",
        link: "/add-city",
        parentId: 20,
      },
      {
        id: 22,
        label: "عرض المدن",
        link: "/view-cities",
        parentId: 20,
      },
    ],
  },
  {
    id: 15,
    label: "المناطق",
    icon: "fas fa-align-justify",
    subItems: [
      {
        id: 21,
        label: "اضافه منطقة",
        link: "/add-region",
        parentId: 20,
      },
      {
        id: 22,
        label: "عرض المناطق",
        link: "/view-region",
        parentId: 20,
      },
    ],
  },
  // {
  //   id: 16,
  //   label: "الصفحات",
  //   icon: "fas fa-align-justify",
  //   subItems: [
  //     {
  //       id: 21,
  //       label: "اضافه صفحة",
  //       link: "/add-pages",
  //       parentId: 20,
  //     },
  //     {
  //       id: 22,
  //       label: "عرض الصفحات",
  //       link: "/view-pages",
  //       parentId: 20,
  //     },
  //   ],
  // },
  {
    id: 7,
    label: "الشروط والأحكام",
    icon: "bx-home-circle",
    link: "/tac",
  },
];
