// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URL: "http://localhost:3000",
  API_URL: "https://sofrtiapi.azurewebsites.net",
  TOKEN_NAME: "SofDAshToken",
  CURRENT_USER: "currentUser",
  USER_TYPE: 'AdminProfile',
  ADMIN_ORDERS_PORTAL: "http://localhost:52521",
  firebase: {
    projectId: 'sofrti-16020',
    appId: '1:1077440221931:web:abc60d02193bcdcd5ba4ae',
    storageBucket: 'sofrti-16020.appspot.com',
    apiKey: 'AIzaSyCAsfiOTAdZ6vfZ0axHXisU0S8FdguouoE',
    authDomain: 'sofrti-16020.firebaseapp.com',
    messagingSenderId: '1077440221931',
    measurementId: 'G-GRZF5242W3',
    vapidKey:'BGUgPGHJSzUk2PSXcs1hbzVSEDTQxhuqKqS4j6SxMp1nFi0MMGA1tHUq5QVg4VwiN-Gv1ZtFLUXF87lRrZHRTJc'
  },
  FIREBASE_TOKEN:'SofkITCHENFirebaseToken'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
